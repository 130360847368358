import React from "react";
import OneLessonADayIcon from "assets/icons/one-lesson-a-day-badge.svg";

import { Tooltip, TooltipContent, TooltipTrigger } from "./Tooltip";

export default ({ testId = "one-lesson-a-day-badge" }) => {
  return (
    <Tooltip>
      <TooltipTrigger>
        <span data-testid={testId}>
          <OneLessonADayIcon className="text-brand-700" />
        </span>
      </TooltipTrigger>
      <TooltipContent>One Lesson a Day</TooltipContent>
    </Tooltip>
  );
};
