import { endOfDay, format, isWithinInterval, startOfDay } from "date-fns";
import groupBy from "lodash/groupBy";
import React, { useCallback, useContext, useMemo } from "react";

import { MERIDIEM_TIME_FORMAT_ZERO_PADDED } from "../../constants";
import DailyTimeBlockItem from "./DailyTimeBlockItem";
import { useSubSessionRequestsQuery, useTimeBlocksQuery } from "./queries";
import DailySubSessionRequestItem from "./DailySubSessionRequestItem";
import { CurrentViewContext } from "../../contexts/Calendar";

export default ({ date, subBoardEnabled }) => {
  const timeBlocksQuery = useTimeBlocksQuery();
  const subRequestsQuery = useSubSessionRequestsQuery();
  const { showSubSessionRequests } = useContext(CurrentViewContext);
  const { calendarEvents: timeBlocks } = timeBlocksQuery.data || {
    calendarEvents: [],
  };
  const { data: subSessionRequests = [] } = subRequestsQuery;
  const timeIndicator = { endDate: new Date(), isTimeIndicator: true, startTime: new Date() };
  const dateIsWithinCurrentDate = useCallback(
    (d) => {
      return isWithinInterval(d, {
        start: startOfDay(date),
        end: endOfDay(date),
      });
    },
    [date]
  );
  const timeBlocksForDate = useMemo(() => {
    return timeBlocks.filter((block) => {
      return dateIsWithinCurrentDate(block.startTime);
    });
  }, [dateIsWithinCurrentDate, timeBlocks]);
  const subSessionRequestsForDate = useMemo(() => {
    const sessions = subSessionRequests.filter((session) => {
      return dateIsWithinCurrentDate(session.startTime);
    });
    const groupedSessions = groupBy(sessions, "startTime");

    return Object.keys(groupedSessions).map((key) => {
      const sessions = groupedSessions[key];

      return {
        endTime: sessions[0].endTime,
        isSubSessionRequest: true,
        sessions,
        startTime: sessions[0].startTime,
      };
    });
  }, [dateIsWithinCurrentDate, subSessionRequests]);

  if (!timeBlocksQuery.data) {
    if (timeBlocksQuery.isError || subRequestsQuery.isError) {
      return (
        <div className="py-1 px-3.5">
          Error: {[timeBlocksQuery.error.message, subRequestsQuery.error.message].join(". ")}
        </div>
      );
    }

    return (
      <div className="py-1 px-3.5">
        <em className="text-zinc-400 text-[13px]">Loading...</em>
      </div>
    );
  }

  if (timeBlocksForDate.length === 0 && subSessionRequestsForDate.length === 0) {
    return (
      <div className="py-1 px-3.5">
        <em className="text-zinc-400 text-[13px]">No scheduled sessions.</em>
      </div>
    );
  }

  return (
    <ul className="flex flex-col p-[4px]">
      {[...timeBlocksForDate, ...subSessionRequestsForDate, timeIndicator]
        .sort((a, b) => a.startTime - b.startTime)
        .map((event) => {
          if (event.isTimeIndicator) {
            return dateIsWithinCurrentDate(timeIndicator.endDate) ? (
              <li className="relative w-full" key="time-indicator">
                <span className="absolute -right-[4px] -left-[8px] h-[1.5px] bg-sky-500 pointer-events-none -translate-y-1/2">
                  <span className="absolute right-full text-white text-[10px] font-medium bg-sky-500 rounded-full -translate-y-1/2 mt-[0.75px] leading-none py-[3px] px-[5px] whitespace-nowrap">
                    {format(timeIndicator.endDate, MERIDIEM_TIME_FORMAT_ZERO_PADDED)}
                  </span>
                </span>
              </li>
            ) : null;
          }

          if (event.isSubSessionRequest) {
            return subBoardEnabled && showSubSessionRequests ? (
              <li className="my-0.5" key={`sub-session-request-${event.startTime.toString()}`}>
                <DailySubSessionRequestItem
                  endTime={event.endTime}
                  sessions={event.sessions}
                  startTime={event.startTime}
                />
              </li>
            ) : null;
          }

          return (
            <li className="my-0.5" key={event.id}>
              <DailyTimeBlockItem
                assignmentType={event.assignmentType}
                endTime={event.endTime}
                gradeLevel={event.grade}
                id={event.id}
                cancellationReason={event.cancellationReason}
                school={event.school}
                startTime={event.startTime}
                status={event.status}
                title={event.title}
                tutorStudentUuid={event.tutorStudentUuid}
              />
            </li>
          );
        })}
    </ul>
  );
};
