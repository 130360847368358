import React, { useMemo } from "react";

import { Drawer, DrawerContent, DrawerTrigger } from "../common/Drawer";
import FeatureToggle from "../common/FeatureToggle";
import InfoTooltip from "../common/InfoTooltip";
import StudentHighFrequencyWordsMasteryDrawer from "../StudentHighFrequencyWordsMasteryDrawer";
import { useAddNewWordMutation, useStudentHighFrequencyWordsDrillQuery } from "./queries";
import Word from "./Word";
import DocumentTextMini from "assets/icons/document-text-mini.svg";
import Binoculars from "assets/icons/binoculars.svg";
import Plus from "assets/icons/plus.svg";
import { duration } from "utils/cardTitle";
import StickyBanner from ".././DurationTimer/StickyBanner";
import DurationTimer from ".././DurationTimer/DurationTimer";
import useCountdownTimer from "../../hooks/useCountdownTimer";
import { RailsPathsProvider } from "../../contexts/RailsPathsContext";

const StudentHighFrequencyWordsDrill = ({
  durationSeconds,
  timerSecondsRemaining,
  displayTimer,
  showStickyTimer,
  paths,
}) => {
  const studentHfwDrillQuery = useStudentHighFrequencyWordsDrillQuery();
  const addNewWordMutation = useAddNewWordMutation();
  const {
    isNextWordPresent,
    masteredHFWCount,
    pronunciationGuideURL,
    totalHFWCount,
    words = [],
  } = studentHfwDrillQuery.data || {};
  const taughtWordsCount = useMemo(() => {
    return words.filter((word) => !word.needsTeaching).length;
  }, [words]);
  const durationTimer = useCountdownTimer(timerSecondsRemaining * 1000);

  if (!studentHfwDrillQuery.data) {
    if (studentHfwDrillQuery.isError) {
      return (
        <span className="flex items-center justify-center text-red-600 min-h-[200px]">
          Error: {studentHfwDrillQuery.error.message}
        </span>
      );
    }

    return (
      <span className="flex items-center justify-center text-gray-400 min-h-[200px]">
        Loading...
      </span>
    );
  }

  return (
    <RailsPathsProvider paths={paths}>
      <div
        className="bg-white rounded-lg border border-l-8 border-zinc-200 flex flex-col px-4 sm:px-10 pt-6 lg:pt-10 pb-4 lg:pb-10 sm:pb-6 space-y-3 sm:space-y-4"
        data-testid="high-frequency-words-lesson-card"
      >
        {showStickyTimer && displayTimer && (
          <StickyBanner key="timer-banner">
            <div className="bg-white border-b border-zinc-200 flex items-center p-2 -mx-4 sm:-ml-10 md:-ml-[6.5rem] sm:-mr-10 justify-end">
              <div className="basis-[350px] flex justify-end">
                <DurationTimer className="flex-row-reverse grow" durationTimer={durationTimer} />
              </div>
            </div>
          </StickyBanner>
        )}
        <div className="flex items-center space-x-3 md:space-x-6 mt-0">
          <Binoculars className="text-zinc-500 w-8 md:w-10 h-8 md:h-10" />
          <div className="flex items-center flex-wrap">
            <h4 className="font-semibold text-xl/none mr-2 text-gray-700">High Frequency Words</h4>
            <FeatureToggle featureName="suggested_card_duration">
              <FeatureToggle.On>
                <p className="text-gray-600 text-sm mr-1">{duration(durationSeconds)}</p>
              </FeatureToggle.On>
            </FeatureToggle>
            <InfoTooltip>
              <p className="font-medium mb-3">High Frequency Words</p>
              <p className="font-medium mb-1.5">Script</p>
              <p className="mb-1.5">
                &quot;We have a new HFW! First we&apos;ll say the word and separate the sounds.
                We&apos;ll count the sounds, and learn the letters that match those sounds!&quot;
              </p>
              <a
                className="flex inline items-center pl-2 text-brand-500 hover:text-brand-800 hover:underline"
                data-testid={`hfw-pronunciation-guide-url`}
                href={pronunciationGuideURL}
                rel="noreferrer"
                target="_blank"
              >
                <DocumentTextMini className="w-4 h-4 mr-1" />
                HFW Pronunciation Guide
              </a>
            </InfoTooltip>
          </div>
          {displayTimer && (
            <div className="flex items-center justify-end grow">
              <DurationTimer className="flex-row-reverse" durationTimer={durationTimer} />
            </div>
          )}
        </div>
        <div className="md:pl-16 flex flex-col xl:flex-row xl:space-x-10 space-y-4 xl:space-y-0">
          <div className="flex-none space-y-2 lg:space-y-4 lg:basis-1/4">
            <div className="text-xs lg:text-sm">
              <Drawer>
                <DrawerTrigger>
                  <button
                    className="text-brand-500 hover:text-brand-800 hover:underline"
                    type="button"
                  >
                    Current Mastery {masteredHFWCount}/{totalHFWCount}
                  </button>
                </DrawerTrigger>
                <DrawerContent>
                  <StudentHighFrequencyWordsMasteryDrawer />
                </DrawerContent>
              </Drawer>
              <div className="text-zinc-400">
                Taught: {taughtWordsCount} of {words.length}
              </div>
            </div>
          </div>
          <ol className="flex flex-wrap lg:basis-3/4">
            {words.map((word, key) => (
              <li className="text-center mr-2 mb-2" key={word.uuid}>
                <Word index={key + 1} {...word} />
              </li>
            ))}
            <li>
              <button
                className="button-secondary py-0 px-0 w-[32px] h-[32px] flex items-center justify-center"
                data-testid="add-new-word-button"
                onClick={addNewWordMutation.mutate}
                disabled={taughtWordsCount !== words.length || !isNextWordPresent}
                type="button"
              >
                <Plus />
              </button>
            </li>
          </ol>
        </div>
      </div>
    </RailsPathsProvider>
  );
};

export default StudentHighFrequencyWordsDrill;
