import cx from "classnames";
import React from "react";
import { NavLink } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const Main = ({ navLinks }) => (
  <div className="border-b border-gray-200 mx-4 sm:mx-0">
    <nav className="-mb-px flex space-x-8">
      {navLinks.map(({ dataTestid, label, to }) => (
        <NavLink
          key={to}
          className={({ isActive }) =>
            twMerge(
              cx(
                "border-transparent text-gray-500 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm",
                "hover:border-gray-300 hover:text-gray-700",
                {
                  "border-brand-500 text-brand-600 hover:border-brand-600 hover:text-brand-700":
                    isActive,
                }
              )
            )
          }
          data-testid={dataTestid}
          end
          to={to}
        >
          {label}
        </NavLink>
      ))}
    </nav>
  </div>
);

export default Main;
