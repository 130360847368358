export const columns = [
  {
    id: "name",
    label: "Last Name, First Name",
    className: "sticky left-0 border border-gray-300 z-40 box-border",
  },
  {
    id: "grade",
    label: "Grade",
    className: "sticky left-[185px] border border-gray-300 box-border",
  },
  {
    id: "programTerms",
    label: "Program Terms",
    className: "bg-gray-300 border-[0.75px] border-gray-300",
  },
  {
    id: "programStartDate",
    label: "Program Start Date",
    className: "bg-gray-300 border-[0.75px] border-gray-300",
  },
  {
    id: "fundingSources",
    label: "Funding Source",
    className: "bg-gray-300 border-[0.75px] border-gray-300",
  },
  {
    id: "tutoringTimeSlot",
    label: "Tutoring Time Slot",
    className: "bg-gray-300 border-[0.75px] border-gray-300",
  },
  {
    id: "email",
    label: "Student Email",
    className: "bg-gray-300 border-[0.75px] border-gray-300",
  },
  {
    id: "dateOfBirth",
    label: "Student DOB",
    className: "bg-gray-300 border-[0.75px] border-gray-300",
  },
  {
    id: "hispanicOrLatinoDescent",
    label: "Hispanic or Latino Descent",
    className: "bg-gray-300 border-[0.75px] border-gray-300",
  },
  {
    id: "race",
    label: "Race",
    className: "bg-gray-300 border-[0.75px] border-gray-300",
  },
  {
    id: "englishLanguageLearner",
    label: "Multilingual Learner",
    className: "bg-gray-300 border-[0.75px] border-gray-300",
  },
  {
    id: "languageSpokenAtHome",
    label: "Language Spoken at Home",
    className: "bg-gray-300 border-[0.75px] border-gray-300",
  },
  {
    id: "teacherName",
    label: "Teacher Name",
    className: "bg-gray-300 border-[0.75px] border-gray-300",
  },
  {
    id: "teacherEmail",
    label: "Teacher Email",
    className: "bg-gray-300 border-[0.75px] border-gray-300",
  },
  {
    id: "id",
    label: "Student ID Number",
    className: "bg-gray-300 border-[0.75px] border-gray-300",
  },
  {
    id: "lunchProgram",
    label: "Lunch Program",
    className: "bg-gray-300 border-[0.75px] border-gray-300",
  },
  {
    id: "specialEducationProgram",
    label: "Special Education Program",
    className: "bg-gray-300 border-[0.75px] border-gray-300",
  },
  {
    id: "specialEducationProgramAccommodations",
    label: "Special Education Program Accommodations",
    className: "bg-gray-300 border-[0.75px] border-gray-300",
  },
];

export const gradeMapping = {
  pre_kindergarten: "Pre-K",
  kindergarten: "K",
  first_grade: "1st",
  second_grade: "2nd",
  third_grade: "3rd",
  fourth_grade: "4th",
  fifth_grade: "5th",
  sixth_grade: "6th",
  seventh_grade: "7th",
  eighth_grade: "8th",
  ninth_grade: "9th",
  tenth_grade: "10th",
  eleventh_grade: "11th",
  twelfth_grade: "12th",
};

export const rowsPerPage = 20;

//mapping of SEP Accommodation values to their respective labels
export const SepAccommodationsMap = {
  additional_adult_support: "Additional Adult Support",
  cfu: "Additional Check for Understandings (CFU)",
  chunking_lessons: "Chunking Lessons",
  enlarged_text_zoom_screen: "Enlarged Text: Zoom Screen",
  extended_time: "Extended Time",
  extra_reminders: "Extra Reminders",
  model_read_for_passages: "Model Read for Passages",
  multiple_choice_answer_format: "Multiple Choice Answer Format",
  none_applicable: "None Applicable to Ignite Reading",
  reduction_of_tasks_activities: "Reduction of Tasks/Activities",
  simplified_chunked_directions: "Simplified/Chunked Directions",
  task_reminders: "Task Reminders",
  visual_cue: "Visual Cue",
  visual_schedule: "Visual Schedule",
};
