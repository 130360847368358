import { startOfWeek } from "date-fns";
import React, { createContext, useState } from "react";

import { CALENDAR_NORMAL_MODE, CALENDAR_WEEKLY_VIEW } from "../constants";

export const CurrentViewContext = createContext(CALENDAR_WEEKLY_VIEW);
export const CurrentDateContext = createContext(new Date());

export const CalendarProvider = ({ children, defaultView }) => {
  const [currentView, setCurrentView] = useState(defaultView || CALENDAR_WEEKLY_VIEW);
  const [calendarViewMode, setCalendarViewMode] = useState(CALENDAR_NORMAL_MODE);
  const [currentDate, setCurrentDate] = useState(
    currentView === CALENDAR_WEEKLY_VIEW ? startOfWeek(new Date()) : new Date()
  );
  const [showSubSessionRequests, setShowSubSessionRequests] = useState(false);

  return (
    <CurrentViewContext.Provider
      value={{
        calendarViewMode,
        currentView,
        setCalendarViewMode,
        setCurrentView,
        setShowSubSessionRequests,
        showSubSessionRequests,
      }}
    >
      <CurrentDateContext.Provider value={{ currentDate, setCurrentDate }}>
        {children}
      </CurrentDateContext.Provider>
    </CurrentViewContext.Provider>
  );
};
