import cx from "classnames";
import { format, isPast } from "date-fns";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import {
  Popover,
  PopoverContent,
  PopoverPositionReference,
  PopoverTrigger,
} from "../common/Popover";
import {
  assignmentTypes,
  cancellationReason,
  MERIDIEM_TIME_FORMAT,
  tutorStudentStatus,
} from "../../constants";
import { SelectedTimeBlockContext } from "../../contexts/TutorCalendar";
import CancellationPill from "./CancellationPill";
import TimeBlockDetails from "./TimeBlockDetails";

const { CANCELLED, INVITED } = tutorStudentStatus;

const DailyTimeBlockItem = ({
  assignmentType,
  cancellationReason,
  endTime,
  gradeLevel,
  id,
  school,
  startTime,
  status,
  title,
  tutorStudentUuid,
}) => {
  const { selectedTimeBlock, setSelectedTimeBlock } = useContext(SelectedTimeBlockContext);
  const onOpenChange = (open) => {
    setSelectedTimeBlock(open ? id : null);
  };

  return (
    <Popover onOpenChange={onOpenChange} open={selectedTimeBlock === id} placement="right">
      <PopoverTrigger discardPositionReference>
        <div
          className={cx(
            "flex items-center justify-between rounded-full py-1 px-2.5 border cursor-pointer relative",
            {
              "bg-brand-100/30 border-brand-500/70 hover:bg-brand-100/60 hover:border-brand-500/100":
                status === INVITED,
              "border-transparent hover:bg-black/[0.035] hover:border-black/[0.04]":
                status !== INVITED,
              "opacity-50": isPast(endTime),
            }
          )}
        >
          <PopoverPositionReference>
            <div className="flex items-center min-w-0">
              <h3 className="mr-2.5 text-[11px] md:text-[13px] truncate">
                <span
                  className={cx(
                    "inline-block w-[110px] md:w-[134px] text-zinc-600 font-light mr-2.5 text-right",
                    {
                      "line-through opacity-60": status === CANCELLED,
                    }
                  )}
                >
                  {format(startTime, MERIDIEM_TIME_FORMAT)} -{" "}
                  {format(endTime, MERIDIEM_TIME_FORMAT)}
                </span>
                <span
                  className={cx("text-[12px] md:text-[14px] text-brand-500 font-medium", {
                    "line-through opacity-60": status === CANCELLED,
                  })}
                  data-heap-redact-text
                >
                  {title}
                </span>
              </h3>
              {status === INVITED ? (
                <div className="bg-sky-400/20 border hidden md:flex flex-nowrap items-center rounded-full text-[10px] text-sky-500 font-medium uppercase px-1.5 py-0.5">
                  <div className="bg-sky-500 rounded-full w-[10px] h-[10px] mr-1" />
                  <span>Suggested</span>
                </div>
              ) : null}
              <CancellationPill status={status} reason={cancellationReason} />
            </div>
          </PopoverPositionReference>
          {status === INVITED ? (
            <div className="items-center hidden md:flex">
              <button
                className="rounded-md bg-brand-600 py-1 px-3 text-[10px] leading-4 font-medium text-white enabled:shadow-sm ring-1 ring-inset ring-brand-700 uppercase ml-2.5 enabled:hover:bg-brand-700 disabled:opacity-50"
                type="button"
              >
                Accept
              </button>
              <button
                className="rounded-md py-1 px-3 text-[10px] leading-4 font-medium text-brand-500 enabled:shadow-sm uppercase ml-2.5 enabled:hover:bg-brand-100 enabled:hover:text-brand-800 disabled:opacity-50"
                type="button"
              >
                Decline
              </button>
            </div>
          ) : null}
          {selectedTimeBlock === id ? (
            <div className="absolute top-0 right-0 bottom-0 left-0 rounded-full bg-sky-500/10 outline outline-sky-500/50 outline-offset-1 pointer-events-none" />
          ) : null}
        </div>
      </PopoverTrigger>
      <PopoverContent>
        <TimeBlockDetails
          assignmentType={assignmentType}
          endTime={endTime}
          gradeLevel={gradeLevel}
          cancellationReason={cancellationReason}
          school={school}
          startTime={startTime}
          status={status}
          title={title}
          tutorStudentUuid={tutorStudentUuid}
        />
      </PopoverContent>
    </Popover>
  );
};

DailyTimeBlockItem.propTypes = {
  assignmentType: PropTypes.oneOf(Object.values(assignmentTypes)).isRequired,
  cancellationReason: PropTypes.oneOf(Object.values(cancellationReason)),
  endTime: PropTypes.instanceOf(Date).isRequired,
  gradeLevel: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  school: PropTypes.string.isRequired,
  startTime: PropTypes.instanceOf(Date).isRequired,
  status: PropTypes.oneOf(Object.values(tutorStudentStatus)).isRequired,
  title: PropTypes.string.isRequired,
  tutorStudentUuid: PropTypes.string.isRequired,
};

export default DailyTimeBlockItem;
