import React, { useMemo } from "react";
import ReteachLessonModal from "./ReteachLessonModal";
import CompleteLessonModal from "./CompleteLessonModal";
import { useCurrentContentQuery } from "../CurrentContent/queries";
import { useFlagOn } from "../../hooks/useFeatureToggle";
import cx from "classnames";
import { twMerge } from "tailwind-merge";

const DISABLED_STATUSES = ["completed", "skipped"];

export default function LessonButton({ content, isBaseline }) {
  const { data: currentContentData } = useCurrentContentQuery();
  const startLessonButton = useFlagOn("start_lesson_button");

  const disableComplete = useMemo(() => {
    if (startLessonButton) {
      if (content.isCurrentContent) {
        return (
          currentContentData?.showStart ||
          currentContentData?.showResume ||
          DISABLED_STATUSES.includes(content.status)
        );
      } else {
        return DISABLED_STATUSES.includes(content.status);
      }
    } else {
      return DISABLED_STATUSES.includes(content.status);
    }
  }, [
    content.isCurrentContent,
    content.status,
    currentContentData?.showResume,
    currentContentData?.showStart,
    startLessonButton,
  ]);

  return [
    <ReteachLessonModal
      key="reteach-modal"
      studentProtocolId={content.studentProtocolId}
      lessonId={content.id}
      isBaseline={isBaseline}
      status={content.status}
    />,
    <CompleteLessonModal
      key="complete-modal"
      studentLessonId={content.studentModelId}
      studentProtocolId={content.studentProtocolId}
      lessonId={content.id}
      isBaseline={isBaseline}
      status={content.status}
    >
      <button
        className={twMerge(
          cx("button-secondary rounded-l-none -ml-[1px]", {
            "disabled:bg-green-100 disabled:text-green-800 disabled:ring-green-300 enabled:hover:bg-green-100":
              content.status === "completed",
          })
        )}
        disabled={disableComplete}
        data-student-lesson-id={content.studentModelId}
        data-student-lesson-status={content.status}
      >
        <div className="w-14">
          {content.status === "skipped" || content.protocolStatus === "tested_out_of"
            ? "Skip"
            : "Complete"}
        </div>
      </button>
    </CompleteLessonModal>,
  ];
}
