import React from "react";
import { useParams } from "react-router-dom";
import { Notebook, LineChart, CalendarCheck } from "@Ignite-Reading/ui-kit/icons";

import ReactRouterTabs from "../common/ReactRouterTabs";

const Navigation = () => {
  const { id: studentId } = useParams();
  const NAV_LINKS = [
    {
      to: `/students/${studentId}`,
      label: (
        <span className="flex gap-1">
          <CalendarCheck /> Today
        </span>
      ),
      dataTestid: "student-today-tab",
    },
    {
      to: `/students/${studentId}/progress`,
      label: (
        <span className="flex gap-1">
          <LineChart /> Progress
        </span>
      ),
      dataTestid: "student-progress-tab",
    },
    {
      to: `/students/${studentId}/notes`,
      label: (
        <span className="flex gap-1">
          <Notebook /> Notes
        </span>
      ),
      dataTestid: "student-notes-tab",
    },
  ];

  return <ReactRouterTabs navLinks={NAV_LINKS} />;
};

export default Navigation;
