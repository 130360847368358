import React, { useContext, useEffect } from "react";

import { CALENDAR_WEEKLY_VIEW } from "../../constants";
import { CurrentViewContext } from "../../contexts/Calendar";
import { useCalendarViewMode } from "../../hooks/useCalendarViewMode";
import DailyView from "./DailyView";
import Header from "./Header";
import WeeklyView from "./WeeklyView";
import SubBoardBanner from "./SubBoardBanner";
import { useSparkCookie } from "../common/SparkCookie";
import { SHOW_SUB_REQUESTS_COOKIE_KEY } from "../TutorApp";

const TutorCalendar = ({ approvalThreshold, subBoardEnabled, timeZoneName, timeZoneOffset }) => {
  const { currentView } = useContext(CurrentViewContext);
  const { isSubMode } = useCalendarViewMode();
  const CurrentView = currentView === CALENDAR_WEEKLY_VIEW ? WeeklyView : DailyView;
  const { showSubSessionRequests, setShowSubSessionRequests } = useContext(CurrentViewContext);
  const [showSubSessionRequestsCookie, setShowSubSessionRequestsCookie] = useSparkCookie(
    SHOW_SUB_REQUESTS_COOKIE_KEY,
    "false"
  );
  useEffect(() => {
    if (showSubSessionRequestsCookie === "true") {
      setShowSubSessionRequests(true);
    }
  }, [setShowSubSessionRequests, showSubSessionRequestsCookie]);
  useEffect(() => {
    setShowSubSessionRequestsCookie(showSubSessionRequests.toString());
  }, [showSubSessionRequests, setShowSubSessionRequestsCookie]);

  return [
    <Header key="header" subBoardEnabled={subBoardEnabled} timeZoneName={timeZoneName} />,
    <CurrentView key="content" subBoardEnabled={subBoardEnabled} timeZoneOffset={timeZoneOffset} />,
    isSubMode ? <SubBoardBanner approvalThreshold={approvalThreshold} key="banner" /> : null,
  ];
};

export default TutorCalendar;
