import React, { useState, useMemo, useRef } from "react";
import { Button } from "@Ignite-Reading/ui-kit";
import SparkyQuestion from "assets/images/sparky-question.svg";
import BookPile from "assets/images/book-pile.svg";
import ArrowLeftOutline from "assets/icons/arrow-left-outline.svg";
import FeatureToggle from "../common/FeatureToggle";
import { ChevronDoubleUpIcon, ChevronDoubleDownIcon } from "@heroicons/react/24/outline";
import cx from "classnames";

const QUERY_COMPLETE_TIMEOUT = 750;

export default function LessonCardWrapper({
  children,
  onStartLesson,
  onResumeLesson,
  showStart = false,
  showResume = false,
  isCurrentLesson = false,
  isLoading = false,
}) {
  const [animate, setAnimate] = useState(false);
  const [minify, setMinify] = useState(false);
  const [isQueryComplete, setIsQueryComplete] = useState(!(showStart || showResume));
  const [isStudentReady, setIsStudentReady] = useState(false);
  const animateRef = useRef(null);

  const showOverlay = useMemo(() => {
    if (isCurrentLesson) {
      return !isQueryComplete || showStart || showResume;
    } else {
      return false;
    }
  }, [isCurrentLesson, isQueryComplete, showStart, showResume]);

  const handleSubmit = (callback) => {
    callback();

    setAnimate(true);

    setTimeout(() => {
      setIsQueryComplete(true);
    }, QUERY_COMPLETE_TIMEOUT);
  };

  return (
    <FeatureToggle featureName="start_lesson_button">
      <FeatureToggle.On>
        <div className="relative">
          {showOverlay && (
            <>
              <div
                style={{
                  backgroundColor: animate ? "rgba(0,0,0,0)" : "rgba(0,0,0,0.05)",
                }}
                className="absolute z-10 -top-2 -bottom-2 -left-2 -right-2 rounded-lg transition-[background-color] duration-500 ease-out"
              />
              <div
                data-testid="animation-layer"
                ref={animateRef}
                style={{
                  bottom:
                    animateRef?.current && animate
                      ? `-${animateRef.current.offsetHeight + 40}px`
                      : "-40px",
                }}
                className={cx(
                  "flex flex-col sm:flex-row bg-white px-4 justify-center items-start sm:items-center min-w-[375px] fixed bottom-0 left-0 lg:left-64 right-0 drop-shadow-[0px_-4px_3px_rgba(0,0,0,0.05)] z-40 duration-500 ease-[cubic-bezier(.52,-0.41,.73,.72)] box-border",
                  {
                    "pt-4 pb-14 lg:pt-10 lg:pb-20": !minify,
                    "pt-4 pb-14": minify,
                    "transition-bottom": animate,
                    "transition-none": !animate,
                  }
                )}
              >
                {!isStudentReady && (
                  <div className="flex content-stretch absolute -top-[40px] right-6 rounded-t-md bg-white box-border">
                    <button
                      data-testid="minify"
                      className="text-brand-600 h-[40px] px-4"
                      onClick={() => setMinify(!minify)}
                      title="Toggle View"
                    >
                      {minify ? (
                        <ChevronDoubleUpIcon width={20} height={20} />
                      ) : (
                        <ChevronDoubleDownIcon width={20} height={20} />
                      )}
                    </button>
                  </div>
                )}

                {!minify && (
                  <a
                    className="text-sm lg:text-base font-medium text-brand-500 hover:text-brand-800 hover:underline sm:absolute mb-2 sm:m-0 ml-4 sm:sl-0 top-6 right-6"
                    data-testid="whats-this-link"
                    href="https://ignite-reading.bettermode.io/spark-links/post/one-lesson-a-day-guide-b5IaBVsxH5CIEXk"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    What&apos;s this?
                  </a>
                )}

                {!minify && (
                  <div data-testid="images" className="mb-4 lg:mb-0 hidden sm:block">
                    {isStudentReady ? (
                      <BookPile className="w-[54px] lg:w-[138px]" data-testid="book-pile" />
                    ) : (
                      <SparkyQuestion
                        className="w-[80px] lg:w-[175px]"
                        data-testid="sparky-question"
                      />
                    )}
                  </div>
                )}

                <div
                  data-testid="content-area"
                  className={cx("w-full relative z-50", {
                    "flex flex-col justify-center md:w-[65%] lg:w-[60%] xl:w-[40%] pl-4 pr-12 md:pl-12":
                      !minify,
                    "px-4": minify,
                  })}
                >
                  {isStudentReady ? (
                    <>
                      <h3 className="text-fuchsia-500 font-semibold text-[20px] leading-[28px] lg:text-[32px] lg:leading-[40px]">
                        You&apos;re ready to {showStart ? "start" : "resume"} your lesson!
                      </h3>
                      <p className="my-2 lg:my-6 text-brand-800 text-sm lg:text-lg">
                        <strong>Make sure the student is here</strong> before{" "}
                        {showStart ? "starting" : "resuming"} the lesson so we can get the most
                        accurate data.
                      </p>

                      {showStart ? (
                        <Button
                          data-testid="start-lesson-button"
                          className="w-full text-sm lg:text-base justify-center"
                          disabled={isLoading || isQueryComplete}
                          onClick={() => handleSubmit(onStartLesson)}
                        >
                          Start Lesson with Student
                        </Button>
                      ) : (
                        <Button
                          data-testid="resume-lesson-button"
                          className="w-full text-sm lg:text-base justify-center"
                          disabled={isLoading || isQueryComplete}
                          onClick={() => handleSubmit(onResumeLesson)}
                        >
                          Resume Lesson with Student
                        </Button>
                      )}

                      <button
                        data-testid="student-not-here-button"
                        className="flex items-center bg-none border-none p-0 text-brand-600 hover:underline font-semibold mt-2 lg:mt-6 text-sm lg:text-base"
                        onClick={() => setIsStudentReady(false)}
                      >
                        <ArrowLeftOutline width="20" height="20" className="mr-2" />
                        Oops, my student&apos;s not here yet
                      </button>
                    </>
                  ) : (
                    <>
                      <h3
                        className={cx("text-fuchsia-500 font-semibold ", {
                          "text-[20px] leading-[28px] lg:text-[32px] lg:leading-[40px]": !minify,
                          "text-[20px] leading-[28px] mb-2": minify,
                        })}
                      >
                        Is your student here?
                      </h3>
                      {!minify && (
                        <>
                          <p className="my-2 lg:my-6 text-brand-800 text-sm lg:text-lg">
                            Not here yet? Keep waiting.
                          </p>
                          <p className="mb-2 lg:mb-6 text-brand-800 text-sm lg:text-lg">
                            You can preview the lesson cards in the background if needed.
                          </p>
                        </>
                      )}
                      <Button
                        data-testid="student-ready-button"
                        className={cx(
                          "bg-white border-brand-600 text-brand-600 hover:bg-brand-100",
                          {
                            "w-full text-sm lg:text-base justify-center": !minify,
                          }
                        )}
                        onClick={() => {
                          setIsStudentReady(true);
                          setMinify(false);
                        }}
                      >
                        Yes, they are here!
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
          <div className="mt-4 space-y-4">{children}</div>
        </div>
      </FeatureToggle.On>
      <FeatureToggle.Off>
        <div className="mt-4 space-y-4">{children}</div>
      </FeatureToggle.Off>
    </FeatureToggle>
  );
}
