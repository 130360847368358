import cx from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

import useStudentAssessmentContext from "./useStudentAssessmentContext";
import { readingAssessmentStatusType } from "../../constants";
import { useStudentAssessmentQuery } from "./queries";
import FeatureToggle from "../common/FeatureToggle";
import InfoTooltip from "../common/InfoTooltip";
import CheckCircleOutline from "assets/icons/check-circle-outline.svg";
import ChevronDownOutline from "assets/icons/chevron-down-outline.svg";
import DurationTimer from ".././DurationTimer/DurationTimer";
import useCountdownTimer from "../../hooks/useCountdownTimer";
import { duration } from "utils/cardTitle";
import StickyBanner from ".././DurationTimer/StickyBanner";

const { COMPLETED, IN_PROGRESS } = readingAssessmentStatusType;

const StudentAssessmentHeader = ({
  timerSecondsRemaining,
  displayTimer,
  showStickyTimer,
  assessmentDuration,
  assessmentName,
  assessmentSubtitle = null,
  children,
  icon,
  tooltipContent,
}) => {
  const studentAssessmentQuery = useStudentAssessmentQuery();
  const { isCollapsible, open, setOpen } = useStudentAssessmentContext();
  const [isDisabled, setDisabled] = useState(false);
  const { status } = studentAssessmentQuery.data || {};
  const durationTimer = useCountdownTimer(timerSecondsRemaining * 1000);

  const onToggle = () => {
    setOpen((prevState) => (isCollapsible ? !prevState : true));
  };
  const onComplete = useCallback(() => {
    setDisabled(true);
  }, [setDisabled]);

  useEffect(() => {
    window.addEventListener("lesson:complete", onComplete);

    return () => {
      window.removeEventListener("lesson:complete", onComplete);
    };
  }, [onComplete]);

  return (
    <>
      {showStickyTimer && displayTimer && status !== COMPLETED && (
        <StickyBanner key="timer-banner">
          <div className="bg-white border-b border-zinc-200 flex items-center p-2 -mx-4 sm:-ml-10 md:-ml-[6.5rem] sm:-mr-10 justify-end">
            <div className="basis-[350px] flex justify-end">
              <DurationTimer className="flex-row-reverse grow" durationTimer={durationTimer} />
            </div>
          </div>
        </StickyBanner>
      )}
      <div className="flex items-center justify-between relative">
        <div className="flex items-center space-x-3 md:space-x-6">
          <div
            className={twMerge(
              cx(
                "text-gray-500 w-8 md:w-10 h-8 md:h-10 flex items-center justify-center shrink-0",
                {
                  "text-fuchsia-500": status === IN_PROGRESS,
                }
              )
            )}
          >
            {icon}
          </div>
          <div className="flex items-center flex-wrap">
            <hgroup>
              <h4 className="flex flex-wrap items-center font-semibold text-lg/tight sm:text-xl/tight mr-2 text-gray-700">
                {assessmentName}
                <FeatureToggle featureName="suggested_card_duration">
                  <FeatureToggle.On>
                    {assessmentDuration && (
                      <p className="text-gray-600 text-sm mx-1 font-normal">
                        {duration(assessmentDuration)}
                      </p>
                    )}
                  </FeatureToggle.On>
                </FeatureToggle>
                {!assessmentSubtitle && tooltipContent && (
                  <InfoTooltip>{tooltipContent}</InfoTooltip>
                )}
              </h4>
              {assessmentSubtitle && (
                <div className="flex items-center space-x-1.5">
                  <p className="text-gray-600 text-sm">{assessmentSubtitle}</p>
                  {tooltipContent && <InfoTooltip>{tooltipContent}</InfoTooltip>}
                </div>
              )}
            </hgroup>
          </div>
        </div>
        {children}
        <div className="flex items-center space-x-2 lg:space-x-10">
          {status === COMPLETED ? (
            <div className="relative">
              <button
                className="button-secondary md:min-w-[130px] pl-8 md:pl-4 py-3 disabled:bg-green-100 disabled:text-green-800 flex items-center justify-center relative"
                disabled
                type="button"
              >
                <CheckCircleOutline className="h-4 w-4 absolute left-2" />
                Done
              </button>
              <p className="text-zinc-500 text-xs lg:text-sm absolute top-full whitespace-nowrap mt-0.5">
                Continue with lesson
              </p>
            </div>
          ) : (
            <>
              {displayTimer && (
                <div className="flex items-center justify-end grow">
                  <DurationTimer className="flex-row-reverse" durationTimer={durationTimer} />
                </div>
              )}
            </>
          )}
          {isCollapsible ? (
            <button
              className={cx(
                "text-zinc-400 disabled:opacity-50 enabled:hover:text-zinc-500 transition-transform",
                {
                  "rotate-180": open,
                }
              )}
              disabled={isDisabled}
              onClick={onToggle}
              type="button"
            >
              <ChevronDownOutline className="rotate-0 h-6 w-6 transition-transform" />
            </button>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default StudentAssessmentHeader;
