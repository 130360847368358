import isFunction from "lodash/isFunction";
import React from "react";

import {
  StudentAssessmentContinueLessonButton,
  useCompleteAssessmentMutation,
  useStudentAssessmentContext,
  useStudentAssessmentQuery,
} from "../StudentAssessment";
import { readingAssessmentStatusType } from "../../constants";
import BookPile from "assets/images/book-pile.svg";

const CompleteAssessmentForm = () => {
  const { COMPLETED } = readingAssessmentStatusType;

  const completeAssessmentMutation = useCompleteAssessmentMutation();
  const { onCompleteAssessment } = useStudentAssessmentContext();
  const studentAssessmentQuery = useStudentAssessmentQuery();
  const { status } = studentAssessmentQuery.data || {};
  const { setOpen } = useStudentAssessmentContext();
  const onSubmit = (event) => {
    if (isFunction(onCompleteAssessment)) {
      onCompleteAssessment(event);
    } else {
      completeAssessmentMutation.mutate(null, {
        onSuccess: () => {
          setOpen(false);
        },
      });
    }
    event.preventDefault();
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="mb-6 flex justify-center">
        <BookPile width="109" height="103" />
      </div>
      {status !== COMPLETED ? (
        <StudentAssessmentContinueLessonButton
          disabled={completeAssessmentMutation.isLoading}
          submitButtonLabel="Continue Lesson"
        />
      ) : null}
    </form>
  );
};

export default CompleteAssessmentForm;
