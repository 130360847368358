import React from "react";

import {
  StudentAssessment,
  StudentAssessmentContentCollapsed,
  StudentAssessmentContentExpanded,
  StudentAssessmentReadingStats,
  StudentAssessmentTimedAssessmentContent,
} from "../StudentAssessment";
import CompleteAssessmentForm from "./CompleteAssessmentForm";
import Header from "./Header";
import Results from "./Results";
import WordList from "./WordList";
import TaskList from "./TaskList";

export const readNumberToTitleMap = {
  1: "Cold Read",
  2: "Model Reading",
  3: "Final Read",
};

export default (props) => (
  <StudentAssessment
    {...props}
    data-testid="fluency-lesson-card"
    queryKey={["student-fluency-assessment", props.assessmentId]}
    resetModalContent="All fluency data you marked will be erased and reset for this lesson. Are you sure you want to reset?"
    resetModalTitle="Reset Fluency"
  >
    <Header
      durationSeconds={props.durationSeconds}
      timerSecondsRemaining={props.timerSecondsRemaining}
      displayTimer={props.displayTimer}
    />
    <StudentAssessmentContentExpanded>
      <StudentAssessmentTimedAssessmentContent
        components={{
          BeforeCountdownTimer: TaskList,
          CompleteAssessmentForm,
          Results,
          UtteranceList: WordList,
        }}
        hideReadingStats={props.hideReadingStats}
        submitResultsButtonLabel={props.submitResultsButtonLabel}
        showStickyTimer={props.showStickyTimer}
      />
    </StudentAssessmentContentExpanded>
    <StudentAssessmentContentCollapsed>
      <StudentAssessmentReadingStats />
    </StudentAssessmentContentCollapsed>
  </StudentAssessment>
);
