import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { get, post, patch } from "../../api";

export const useLessonPlanQuery = (studentId, studentLessonId) => {
  return useQuery({
    queryKey: ["lesson-plan", studentId, studentLessonId],
    queryFn: async ({ signal }) => {
      const response = await get(`/students/${studentId}/lesson_plan`, {
        signal,
      });

      return response.data;
    },
    refetchOnWindowFocus: false,
    staleTime: 0,
  });
};

export const useStartLessonMutation = (studentId, studentLessonId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return post(`/students/${studentId}/student_lessons/${studentLessonId}/start`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["current-content", studentId] });
    },
  });
};

export const useResumeLessonMutation = (studentId, studentLessonId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return patch(`/students/${studentId}/student_lessons/${studentLessonId}/resume`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["current-content", studentId] });
    },
  });
};
