import { Button } from "@Ignite-Reading/ui-kit/components";
import { twcx } from "@Ignite-Reading/ui-kit/utils";
import React, { useEffect, useRef, useState } from "react";

const KEEP_BUTTON_DISABLED_TIMEOUT = 3000;

const JoinSessionButton = ({ className, clientURL }) => {
  const disabledTimerRef = useRef(null);
  const [disabled, setDisabled] = useState(false);
  const onClick = () => {
    // We use clientURL—since it's unique—as the window name to prevent opening multiple tabs
    window.open(clientURL, clientURL);
    setDisabled(true);
    disabledTimerRef.current = setTimeout(() => {
      setDisabled(false);
    }, KEEP_BUTTON_DISABLED_TIMEOUT);
  };

  useEffect(() => {
    return () => {
      clearTimeout(disabledTimerRef.current);
    };
  }, []);

  return (
    <Button
      onClick={onClick}
      className={twcx("button-primary whitespace-nowrap", className)}
      disabled={!clientURL || disabled}
      data-testid="join-nsb-button"
    >
      Join Session
    </Button>
  );
};

export default JoinSessionButton;
