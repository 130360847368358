import cx from "classnames";
import { endOfWeek, isSameDay, isWithinInterval, startOfWeek } from "date-fns";
import React, { useContext } from "react";

import { CurrentDateContext, CurrentViewContext } from "../../../contexts/Calendar";
import {
  CALENDAR_DAILY_VIEW,
  CALENDAR_MONTLY_VIEW,
  CALENDAR_WEEKLY_VIEW,
} from "../../../constants";
import { useCalendarViewMode } from "../../../hooks/useCalendarViewMode";
import PropTypes from "prop-types";

const ViewSwitcher = ({ views, onChange }) => {
  const now = new Date();
  const { currentDate, setCurrentDate } = useContext(CurrentDateContext);
  const { currentView, setCurrentView } = useContext(CurrentViewContext);
  const { isCalendarMode, isSubMode } = useCalendarViewMode();
  const onChangeView = (event) => {
    setCurrentView(event.target.value);

    /*
     * When today is within view, we want to ensure it appears as the first item
     * when viewing the calendar in DAILY_VIEW.
     * In DAILY_VIEW, today refers to the currentDate, whereas in WEEKLY_VIEW
     * currentDate represents the start of week.
     */
    if (event.target.value === CALENDAR_DAILY_VIEW) {
      if (
        isWithinInterval(currentDate, {
          start: startOfWeek(now),
          end: endOfWeek(now),
        })
      ) {
        setCurrentDate(now);
      }
    } else if (event.target.value === CALENDAR_WEEKLY_VIEW) {
      if (isSameDay(currentDate, now)) {
        setCurrentDate(startOfWeek(now));
      }
    }
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <div
      className={cx(
        "flex items-center rounded-[8px] ring-1 ring-inset p-[3px] h-full",
        isCalendarMode ? "bg-gray-100/75 ring-gray-300" : "bg-zinc-700/60 ring-zinc-600"
      )}
      data-testid="view-switcher"
    >
      {views.map((view) => [
        <input
          checked={currentView === view}
          className={cx("hidden", {
            // We can't build this class names dynamically because Tailwind won't be able to pick up on them and
            // generate them correctly.
            "peer/daily": view === CALENDAR_DAILY_VIEW,
            "peer/weekly": view === CALENDAR_WEEKLY_VIEW,
            "peer/monthly": view === CALENDAR_MONTLY_VIEW,
          })}
          id={`view-${view}`}
          key="input"
          name="view"
          onChange={onChangeView}
          type="radio"
          value={view}
        />,
        <label
          className={cx(
            "capitalize cursor-pointer rounded-md text-xs md:text-sm font-medium text-center px-1 md:px-2.5 leading-none flex items-center justify-center h-full w-[56px] md:w-[68px] lg:w-[80px]",
            isCalendarMode ? "text-gray-700" : "text-white",
            {
              "peer-checked/daily:bg-white peer-checked/daily:shadow":
                view === CALENDAR_DAILY_VIEW && isCalendarMode,
              "peer-checked/daily:bg-zinc-700 peer-checked/daily:shadow":
                view === CALENDAR_DAILY_VIEW && isSubMode,
              "peer-checked/weekly:bg-white peer-checked/weekly:shadow":
                view === CALENDAR_WEEKLY_VIEW && isCalendarMode,
              "peer-checked/weekly:bg-zinc-700 peer-checked/weekly:shadow":
                view === CALENDAR_WEEKLY_VIEW && isSubMode,
              "peer-checked/monthly:bg-white peer-checked/monthly:shadow":
                view === CALENDAR_MONTLY_VIEW && isCalendarMode,
              "peer-checked/monthly:bg-zinc-700 peer-checked/monthly:shadow":
                view === CALENDAR_MONTLY_VIEW && isSubMode,
            }
          )}
          htmlFor={`view-${view}`}
          key="label"
        >
          {view}
        </label>,
      ])}
    </div>
  );
};

ViewSwitcher.propTypes = {
  views: PropTypes.arrayOf(
    PropTypes.oneOf([CALENDAR_DAILY_VIEW, CALENDAR_WEEKLY_VIEW, CALENDAR_MONTLY_VIEW])
  ),
};

export default ViewSwitcher;
