import React from "react";
import { useLessonPlanQuery, useStartLessonMutation, useResumeLessonMutation } from "./queries";
import { useCurrentContentQuery } from "../CurrentContent/queries";
import LessonCardRenderer from "./LessonCardRenderer";
import Wrapper from "./Wrapper";

export default function LessonPlan({ studentId, studentLessonId }) {
  const { data, isError, error = {} } = useLessonPlanQuery(studentId, studentLessonId) || {};
  const { mutate: handleStartLesson, isLoading: isStartLoading } = useStartLessonMutation(
    studentId,
    studentLessonId
  );
  const { mutate: handleResumeLesson, isLoading: isResumeLoading } = useResumeLessonMutation(
    studentId,
    studentLessonId
  );
  const {
    data: currentContentData,
    isError: isCurrentContentError,
    error: currentContentError = {},
  } = useCurrentContentQuery();

  if (!data || !currentContentData) {
    if (isError || isCurrentContentError) {
      return (
        <span className="flex items-center justify-center text-red-600 min-h-[200px]">
          Error: {error?.message || currentContentError?.message}
        </span>
      );
    }

    return (
      <span className="flex items-center justify-center text-gray-400 min-h-[200px]">
        Loading...
      </span>
    );
  }

  return (
    <Wrapper
      onResumeLesson={handleResumeLesson}
      onStartLesson={handleStartLesson}
      showResume={currentContentData.showResume}
      showStart={currentContentData.showStart}
      isCurrentLesson={currentContentData.studentLessonId === studentLessonId}
      isLoading={isStartLoading || isResumeLoading}
    >
      {data.map(
        ({
          durationSeconds,
          gradeLevels,
          id,
          isConditional,
          open,
          paDrillType,
          sequenceNumber,
          targetGoal,
          timerSecondsRemaining,
          type,
        }) => (
          <LessonCardRenderer
            durationSeconds={durationSeconds}
            gradeLevels={gradeLevels}
            id={id}
            isConditional={isConditional}
            key={`${type}-${id}`}
            open={open}
            paDrillType={paDrillType}
            sequenceNumber={sequenceNumber}
            showResume={currentContentData.showResume}
            showStart={currentContentData.showStart}
            studentId={studentId}
            targetGoal={targetGoal}
            timerSecondsRemaining={timerSecondsRemaining}
            type={type}
          />
        )
      )}
    </Wrapper>
  );
}
