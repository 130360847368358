import cx from "classnames";
import { format, isPast } from "date-fns";
import PropTypes from "prop-types";
import React, { useContext, useMemo } from "react";
import { twMerge } from "tailwind-merge";

import DocumentCheckOutline from "assets/icons/document-check-outline.svg";
import DocumentMagnifyingGlassOutline from "assets/icons/document-magnifying-glass-outline.svg";
import { Popover, PopoverContent, PopoverTrigger } from "../common/Popover";
import { Tooltip, TooltipContent, TooltipTrigger } from "../common/Tooltip";
import { SelectedTimeBlockContext } from "../../contexts/TutorCalendar";
import {
  assignmentTypes,
  cancellationReason,
  HOUR_MINUTE_TWELVE,
  tutorStudentStatus,
  tutorTimeOffStatus,
} from "../../constants";
import TimeBlockDetails from "./TimeBlockDetails";
import { useCalendarViewMode } from "../../hooks/useCalendarViewMode";
import { useSubBoardSelector } from "../../hooks/useSubBoardSelector";
import { useTutorTimeOffs } from "../../hooks/useTutorTimeOff";

const { ACCEPTED, CANCELLED, INVITED, SUB_REQUESTED, CLAIMED } = tutorStudentStatus;
const { APPROVED, PENDING } = tutorTimeOffStatus;

const WeeklyTimeBlockItem = ({
  assignmentType,
  cancellationReason,
  columnSelected,
  endTime,
  gradeLevel,
  hideTimestamp,
  id,
  school,
  startTime,
  status,
  title,
  tutorStudentUuid,
}) => {
  const { selectedTimeBlock, setSelectedTimeBlock } = useContext(SelectedTimeBlockContext);
  const { isSubMode, isCalendarMode } = useCalendarViewMode();
  const { timeBlockIsSelected, deselectDate, selectTimeBlock, deselectTimeBlock } =
    useSubBoardSelector();
  const { getTimeOffStatus } = useTutorTimeOffs();
  const onOpenChange = (open) => {
    if (!isSubMode) {
      setSelectedTimeBlock(open ? id : null);
    }
  };
  const selected = useMemo(() => {
    return columnSelected || timeBlockIsSelected(startTime);
  }, [columnSelected, timeBlockIsSelected, startTime]);
  const tutorTimeOffStatus = useMemo(() => {
    return getTimeOffStatus(new Date(startTime));
  }, [startTime, getTimeOffStatus]);
  const onClickTimeBlock = () => {
    if (isSubMode && status === ACCEPTED && tutorTimeOffStatus === null) {
      if (selected) {
        deselectTimeBlock(startTime);
        deselectDate(startTime);
      } else {
        selectTimeBlock(startTime);
      }
    }
  };
  const styles = useMemo(() => {
    if (status === SUB_REQUESTED) {
      return "bg-cyan-400 border-transparent hover:opacity-80";
    } else if (status === CLAIMED) {
      return "opacity-70 pattern-diagonal-lines pattern-cyan-400 pattern-bg-cyan-500 pattern-size-1 pattern-opacity-100 border-cyan-500 hover:opacity-80";
    } else if (isSubMode) {
      return cx({
        "bg-zinc-600 border-transparent hover:bg-zinc-600/70 hover:border-zinc-600/70":
          status === ACCEPTED && !selected,
        "bg-brand-700 border-transparent hover:bg-brand-700/70 hover:border-brand-900/70":
          status === ACCEPTED && selected,
        "bg-zinc-800 border-brand-500/70 hover:bg-zinc-600/30 hover:border-brand-500/100":
          status === INVITED && !selected,
        "bg-brand-700 border-brand-500 hover:bg-brand-700/70 hover:border-brand-500/70":
          status === INVITED && selected,
        "opacity-60 pattern-diagonal-lines pattern-zinc-600 pattern-bg-zinc-800 pattern-size-1 pattern-opacity-100 border-zinc-800 hover:opacity-80":
          status === CANCELLED,
      });
    } else if (tutorTimeOffStatus) {
      return cx({
        "bg-zinc-400 border-transparent hover:opacity-80":
          tutorTimeOffStatus === PENDING && status !== INVITED,
        "bg-zinc-400/60 border-brand-500/70 hover:opacity-80":
          tutorTimeOffStatus === PENDING && status === INVITED,
        "opacity-100 pattern-diagonal-lines pattern-zinc-300 pattern-bg-zinc-400 pattern-size-1 pattern-opacity-100 border-transparent hover:opacity-80":
          tutorTimeOffStatus === APPROVED,
      });
    } else if (isCalendarMode) {
      return cx({
        "bg-brand-600 border-transparent hover:bg-brand-700 hover:border-brand-700": ![
          INVITED,
          CANCELLED,
          SUB_REQUESTED,
          CLAIMED,
        ].includes(status),
        "bg-brand-100/30 border-brand-500/70 hover:bg-brand-100/60 hover:border-brand-500/100":
          status === INVITED,
        "opacity-70 pattern-diagonal-lines pattern-brand-400 pattern-bg-brand-500 pattern-size-1 pattern-opacity-100 hover:opacity-80":
          status === CANCELLED,
        "opacity-50": isPast(endTime),
      });
    }
  }, [endTime, tutorTimeOffStatus, isSubMode, isCalendarMode, selected, status]);

  return (
    <Popover
      offset={{ alignmentAxis: -20 }}
      onOpenChange={onOpenChange}
      open={selectedTimeBlock === id && !isSubMode}
      placement="bottom-start"
    >
      <PopoverTrigger>
        <div
          className={twMerge(
            cx(
              "flex items-start justify-between rounded-[15px] h-full px-2.5 py-[4.5px] border cursor-pointer relative",
              styles
            )
          )}
          onClick={onClickTimeBlock}
        >
          <div className="flex flex-nowrap items-center grow whitespace-nowrap w-full">
            <h3
              className={cx(
                "text-[12px] grow leading-tight truncate",
                status === INVITED
                  ? isSubMode
                    ? "text-zinc-500"
                    : "text-brand-500"
                  : "text-white",
                status === CLAIMED || status === SUB_REQUESTED ? "mr-5" : "mr-2.5"
              )}
            >
              {hideTimestamp ? null : (
                <span
                  className={cx(
                    "hidden md:inline-block w-[32px] text-right font-light mr-2.5",
                    isSubMode
                      ? "text-white/70"
                      : status === INVITED
                        ? "text-gray-600"
                        : "text-white/70",
                    { "line-through": status === CANCELLED || status === CLAIMED }
                  )}
                >
                  {format(startTime, HOUR_MINUTE_TWELVE)}
                </span>
              )}
              <span
                className={cx("text-[11px] md:text-[13px] font-medium", {
                  "line-through": status === CANCELLED || status === CLAIMED,
                })}
                data-heap-redact-text
              >
                {title}
              </span>
            </h3>
            {status === INVITED ? (
              <div className="bg-sky-500 rounded-full w-[10px] h-[10px] mr-1 shrink-0 hidden md:block" />
            ) : null}
          </div>
          {status === CANCELLED ? (
            <div className="absolute top-0 bottom-0 left-0 right-0 " />
          ) : null}
          {selectedTimeBlock === id ? (
            <div className="absolute top-0 right-0 bottom-0 left-0 rounded-[15px] bg-sky-500/20 outline outline-sky-500 outline-offset-1 pointer-events-none" />
          ) : null}
          {status === CLAIMED || status === SUB_REQUESTED ? (
            <Tooltip>
              <TooltipTrigger>
                <div className="absolute right-1.5 w-[20px] h-[20px] text-white">
                  {status === CLAIMED ? (
                    <DocumentCheckOutline className="w-5 h-5" />
                  ) : (
                    <DocumentMagnifyingGlassOutline className="w-5 h-5" />
                  )}
                </div>
              </TooltipTrigger>
              <TooltipContent>
                Your sub session has been {status === CLAIMED ? "claimed" : "requested"}.
              </TooltipContent>
            </Tooltip>
          ) : null}
        </div>
      </PopoverTrigger>
      <PopoverContent>
        <TimeBlockDetails
          assignmentType={assignmentType}
          endTime={endTime}
          gradeLevel={gradeLevel}
          cancellationReason={cancellationReason}
          school={school}
          startTime={startTime}
          status={status}
          title={title}
          tutorStudentUuid={tutorStudentUuid}
        />
      </PopoverContent>
    </Popover>
  );
};

WeeklyTimeBlockItem.propTypes = {
  assignmentType: PropTypes.oneOf(Object.values(assignmentTypes)).isRequired,
  cancellationReason: PropTypes.oneOf(Object.values(cancellationReason)),
  columnSelected: PropTypes.bool.isRequired,
  endTime: PropTypes.instanceOf(Date).isRequired,
  gradeLevel: PropTypes.string.isRequired,
  hideTimestamp: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  school: PropTypes.string.isRequired,
  startTime: PropTypes.instanceOf(Date).isRequired,
  status: PropTypes.oneOf(Object.values(tutorStudentStatus)).isRequired,
  title: PropTypes.string.isRequired,
  tutorStudentUuid: PropTypes.string.isRequired,
};

export default WeeklyTimeBlockItem;
