import React from "react";
import Header from "./Header";
import ExpandedContent from "./ExpandedContent";
import Stats from "./Stats";
import {
  StudentAssessment,
  StudentAssessmentContentCollapsed,
  StudentAssessmentContentExpanded,
} from "../StudentAssessment";
import PropTypes from "prop-types";

export default function StudentSharedReadingAssessment({
  assessmentId,
  displayTimer = true,
  showStickyTimer = true,
  open = false,
  paths,
  timerSecondsRemaining,
}) {
  return (
    <StudentAssessment
      assessmentId={assessmentId}
      paths={paths}
      open={open}
      data-testid="shared-reading-lesson-card"
      queryKey={["student-shared-reading-assessment", assessmentId]}
    >
      <Header
        displayTimer={displayTimer}
        showStickyTimer={showStickyTimer}
        timerSecondsRemaining={timerSecondsRemaining}
      />
      <StudentAssessmentContentExpanded>
        <Stats />
        <ExpandedContent />
      </StudentAssessmentContentExpanded>
      <StudentAssessmentContentCollapsed>
        <Stats />
      </StudentAssessmentContentCollapsed>
    </StudentAssessment>
  );
}

StudentSharedReadingAssessment.propTypes = {
  assessmentId: PropTypes.number.isRequired,
  open: PropTypes.bool,
  paths: PropTypes.object.isRequired,
};
