import React, { useContext } from "react";
import { RailsPathsContext } from "../../contexts/RailsPathsContext";
import { patch } from "../../api";

export default () => {
  const paths = useContext(RailsPathsContext);

  if (paths.updateSecondsRemaining) {
    /*
     * This hidden form is used by QA automation to update remaining seconds of a lesson card.
     */
    return (
      <button
        className="hidden"
        data-component="qa-tools"
        data-path={paths.updateSecondsRemaining}
        data-testid="update-seconds-remaining-submit-button"
        tabIndex="-1"
        type="button"
        onClick={async () => {
          await patch(paths.updateSecondsRemaining, {
            seconds_remaining: "0",
          });

          // Reload to show the updated zeroed out timer
          window.location.reload();
        }}
      />
    );
  } else {
    return false;
  }
};
